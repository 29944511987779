body {
  margin: 0;
  background: #f9f9f9;
}

.app-container {
  margin: 20px 20px 0px 20px;
  background: #f9f9f9;
}

.image-section {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  background: #f9f9f9;
}

.main-image {
  width: 50%;
}

.payment-form {
  width: 80%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.payment-form-box {
  width: 100%;
  padding: 20px 40px 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.payment-form-items {
  margin: 40px;
}

.payment-form-items>* {
  margin-bottom: 30px;
}

.input-label {
  font-size: 30px;
}

.input-amount {
  padding-top: 5px;
  font-size: 24px;
  width: 60%;
}

.input-text {
  font-size: 26px;
  width: 60%;
}

.submit-button-section {
  padding-top: 20px;
}

.submit-button {
  font-size: 28px;
  height: 60px;
}

@media screen and (max-width: 800px) {
  .payment-form {
    width: 100%;
  }

  .payment-form-items {
    margin: 0px;
  }

  .main-image {
    width: 80%;
  }

  .input-label {
    font-size: 24px;
  }

  .input-amount {
    padding-top: 10px;
    font-size: 24px;
    height: 60px;
    width: 60%;
  }

  .input-text {
    font-size: 20px;
    width: 100%;
  }

  .submit-button {
    font-size: 24px;
    height: 50px;
  }
}